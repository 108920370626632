import { type SearchConsole } from 'types/search_console';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export const getSearchConsole = async (
  analyticId: string,
): Promise<{ searchConsole: SearchConsole }> => {
  const requestPath = `/api/v1/analytics/${analyticId}/search_console`;

  const response = await getRequest(requestPath);

  return StringUtil.snakeObjectToCamelObject(await response.json()) as {
    searchConsole: SearchConsole;
  };
};
